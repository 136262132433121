@font-face {
    font-family: 'League Gothic';
    src: url('./league-gothic.eot');
    src: url('./league-gothic.eot?#iefix') format('embedded-opentype'),
         url('./league-gothic.woff') format('woff'),
         url('./league-gothic.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
}
